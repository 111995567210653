<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': sidebars.leftSidebar}">
      <FilterUsersForm v-if="$store.state.sidebars.leftSidebar" />
    </SidebarLeft>

    <TableContainer
      ref="table-container"
      :columns="tableDataUsers"
      :counter-filter="$store.state.users.searchCount"
      :is-archive="$can('edit', 'user-read-archive')"
      :is-archiving="$can('edit', 'user-trash')"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'user-delete')"
      :rows="$store.state.users.users"
      :show-add-button="false"
      :sorting-fields="sortingFields"
      :title="$t('Users') || ''"
      is-archive
      name="users"
      @on-selected="selectUser"
      @update-data="updateData"
      @archive-items="archiveItems"
      @delete-item="deleteItem"
      @return-from-archive="returnFromArchive"
      @next-page="loadNewData"
      @on-change-archive="
        () => {
          $store.commit('users/changePage', 1)
          if ($route.name !== 'users') $router.push({name: 'users'})
        }
      "
    >
      <template v-slot:head-buttons-after>
        <ZemButton v-if="$can('edit', 'user-create')" icon @click="showRightSidebar">
          <img :src="require('@/assets/icons/plus.svg')" alt="" class="tw-mb-[1px]" />
          <span class="mobile-d-none">Пользователь</span>
        </ZemButton>
      </template>
    </TableContainer>

    <SidebarRight :class="{'sidebar-right--show': sidebars.rightSidebar}">
      <DetailsUser v-if="!newItem" @on-show-modal="showModal = true" />

      <div v-else-if="newItem" class="sidebar__container">
        <zem-card class="d-flex justify-content-between">
          <zem-card-title class="mb-0">Регистрация пользователя</zem-card-title>
          <div
            @click="
              () => {
                $store.commit('sidebars/changeRightSidebar', false)
              }
            "
          >
            <zem-icon class="cursor-pointer" name="close" size="16" />
          </div>
        </zem-card>
        <zem-card class="mt-2">
          <validation-observer ref="userForm">
            <div class="zem-collapse-table rows">
              <div class="zem-collapse-table__row">
                <div class="zem-collapse-table__column title">Имя</div>
                <div class="zem-collapse-table__column text">
                  <ValidationProvider v-slot="{errors}" :rules="{required: true}" class="w-100" name="Имя">
                    <zem-input
                      v-model="user.name"
                      :max-length="255"
                      autocomplete="h87h58g7h8hd"
                      class="mt-0"
                      placeholder="Введите имя"
                    />
                    <p v-show="errors.length !== 0" class="details-form__data-block__error tw-whitespace-normal">
                      {{ errors[0] }}
                    </p>
                  </ValidationProvider>
                </div>
              </div>
              <div class="zem-collapse-table__row">
                <div class="zem-collapse-table__column title">Email</div>
                <div class="zem-collapse-table__column text">
                  <ValidationProvider
                    v-slot="{errors}"
                    :rules="{required: true, email: true}"
                    class="w-100"
                    name="Email"
                  >
                    <zem-input
                      v-model="user.email"
                      :max-length="255"
                      autocomplete="h87h58g7h8hd"
                      class="mt-0"
                      placeholder="Введите email"
                    />
                    <p v-show="errors.length !== 0" class="details-form__data-block__error tw-whitespace-normal">
                      {{ errors[0] }}
                    </p>
                  </ValidationProvider>
                </div>
              </div>
              <div class="zem-collapse-table__row">
                <div class="zem-collapse-table__column title">Пароль</div>
                <div class="zem-collapse-table__column text">
                  <ValidationProvider
                    v-slot="{errors}"
                    :rules="{
                      required: true,
                      regex: `^[^а-яА-ЯёЁ]{8,}$`,
                    }"
                    class="w-100"
                    name="Пароль"
                  >
                    <zem-input
                      v-model="user.password"
                      :max-length="255"
                      autocomplete="h87h58g7h8hd"
                      class="mt-0"
                      placeholder="Введите пароль"
                      type="password"
                    />
                    <p v-show="errors.length !== 0" class="details-form__data-block__error tw-whitespace-normal">
                      Допустимы только латинские буквы, цифры и спец символы. Минимальная длина 8 символов.
                    </p>
                  </ValidationProvider>
                </div>
              </div>
              <div class="zem-collapse-table__row">
                <div class="zem-collapse-table__column title">Роль</div>
                <div class="zem-collapse-table__column text overflow-unset">
                  <v-select
                    v-model="selectedUsersRoles"
                    :components="{OpenIndicator}"
                    :disabled="!$can('edit', 'manage-admins-access')"
                    :options="rolesForRegister"
                    class="mt-0"
                    label="title"
                    multiple
                    placeholder="Выбрать"
                    @input="changeRoles($event)"
                  >
                    <template #open-indicator="{attributes}">
                      <img :src="require('@/assets/icons/dropdown-arrow.svg')" alt="" />
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="zem-collapse-table__row">
                <div class="zem-collapse-table__column title">{{ $t('User amoCRM') }}</div>
                <div class="zem-collapse-table__column text overflow-unset">
                  <ZemDropdownList
                    v-model="selectedUserAmoCRM"
                    :options="$store.state.users.usersAmoCRM"
                    :placeholder="'Выбрать'"
                    class="px-0 mt-0"
                    @input="user.amocrm_user_id = $event.value"
                  />
                </div>
              </div>
            </div>
          </validation-observer>
          <zem-button :disabled="loadingCreateUser" :loading="loadingCreateUser" class="mt-2" @click="handleRegister">
            Зарегистрировать
          </zem-button>
        </zem-card>
      </div>

      <DetailsFilter v-if="selectedRowsUsers.length > 1" :count="selectedRowsUsers.length" :fields.sync="fieldsUsers" />
    </SidebarRight>

    <ZemModal
      :show="showModal"
      :title="$t('Change the password for a user') || ''"
      :title-cancel="$t('Cancel') || ''"
      :title-success="$t('Change') || ''"
      color-cancel="grey"
      @on-change="showModal = !showModal"
      @on-success="updatePassword"
      @on-cancel="showModal = false"
    >
      <validation-observer>
        <ValidationProvider
          v-slot="{errors}"
          :rules="{required: true, regex: `^[^а-яА-ЯёЁ]{8,}$`}"
          class="w-100"
          name="Пароль"
        >
          <ZemInput v-model="newPassword" :title="$t('New password') || ''" class="mt-1" type="text" />
          <p v-show="errors.length !== 0" class="details-form__data-block__error tw-whitespace-normal tw-max-w-[260px]">
            Допустимы только латинские буквы, цифры и спец символы. Минимальная длина 8 символов.
          </p>
        </ValidationProvider>
      </validation-observer>
    </ZemModal>
  </div>
</template>

<script>
import SidebarLeft from '../components/SidebarLeft'
import SidebarRight from '../components/SidebarRight'
import UserCard from '../components/UserCard'
import CollapseCard from '../components/CollapseCard'
import DetailsFilter from '@/components/DetailsFilter'
import {errorHandler, formattedPhone} from '@/assets/scripts/scripts'
import FilterUsersForm from '@/components/FilterUsersForm'
import UsersService from '@/services/users.service'
import ZemLink from '@/components/ui/ZemLink'
import ZemModal from '@/components/ui/ZemModal'
import ZemInput from '@/components/ui/ZemInput'
import TableContainer from '@/components/TableContainer.vue'
import ZemCard from '@/components/ui/ZemCard.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemIcon from '@/components/ui/ZemIcon.vue'
import store from '@/store'
import AuthService from '@/services/auth.service'
import OtherService from '@/services/other.service'
import {eventBus} from '@/main'
import RolesService from '@/services/roles.service'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import DetailsUser from '@/components/DetailsUser.vue'

export default {
  name: 'Users',
  components: {
    DetailsUser,
    ZemDropdownList,
    ZemLink,
    ZemModal,
    ZemInput,
    SidebarLeft,
    TableContainer,
    SidebarRight,
    UserCard,
    CollapseCard,
    FilterUsersForm,
    DetailsFilter,
    ZemCard,
    ZemButton,
    ZemCardTitle,
    ZemIcon,
  },
  data() {
    return {
      newItem: false,
      itemInfo: {},
      fields: [],
      count: 0,
      editUser: false,
      user: {
        name: '',
        email: '',
        password: '',
        amocrm_user_id: '',
        roles: [],
      },
      usersRoles: [],
      selectedUsersRoles: [],
      selectedUserAmoCRM: {
        title: '',
        value: '',
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      sortingFields: [0, 1, 2],
      tableDataUsers: [
        {
          label: 'ID',
          field: 'number',
          sortField: 'id',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'ФИО',
          field: 'name',
          sortField: 'name',
          showInTable: true,
          sort: 0,
        },
        {
          label: 'Роль',
          field: 'roles',
          sortField: 'role_id',
          showInTable: true,
          sort: 0,
        },
      ],
      showModal: false,
      newPassword: '',
      loadingCreateUser: false,
    }
  },

  mounted() {
    UsersService.getAllUsers()
    UsersService.getUsersAmoCRM()
    const {id} = this.$route.params
    if (id) this.$store.commit('users/itemsForDelete', [id])
    this.updateData()
    OtherService.getRoles()
    RolesService.getRolesList().then(r => {
      this.rolesForRegister = r.data.data
    })
    const width = window.innerWidth
    this.$store.commit('sidebars/changeLeftSidebar', width > 768)
    this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
  },

  computed: {
    rolesForRegister: {
      get() {
        return store.state.other.rolesForRegister
      },
      set(val) {
        store.commit('other/getRolesForRegister', val)
      },
    },
    role() {
      return this.$store.state.user.role
    },
    sidebars() {
      return this.$store.state.sidebars
    },
    selectedRowsUsers() {
      return this.$store.state.users.selectedItemsForDelete
    },
    calculateOnePlusOne() {
      return (1 + 1) * 2
    },
    fieldsUsers() {
      let arr = []
      if (this.$store.state.users.selectedUsers !== undefined) {
        this.$store.state.users.selectedUsers.forEach(item => {
          arr.push({
            title: `${this.$t('People')} ${item.number}`,
            data: [
              {
                name: this.$t('fio'),
                value: item.fio,
                new: false,
                read: true,
                type: 'text',
              },
              {
                name: this.$t('Phone'),
                value: formattedPhone(item.phone),
                new: false,
                read: true,
                type: 'phone',
              },
              {
                name: this.$t('Email'),
                value: item.email,
                new: false,
                read: true,
                type: 'text',
              },
            ],
          })
        })
      }
      return arr
    },
  },

  watch: {
    selectedRowsUsers() {
      UsersService.getSelectedUsers()
    },
  },

  methods: {
    changeRoles(event) {
      this.user.roles = event.map(el => {
        return el.value
      })
    },
    async updateData() {
      const users = await UsersService.getAllUsers()
      const {id} = this.$route.params
      if (id) {
        const data = users.data.data.find(el => el.id === id)
        const index = users.data.data.findIndex(el => el.id === id)
        this.selectUser({
          row: data,
          rowIndex: index,
        })
      }
    },
    loadNewData() {
      const page = this.$store.state.users.paginationPage
      if (this.$store.state.users.users.length) {
        this.$store.commit('users/changePage', page + 1)
        UsersService.getAllUsers(false, false, true)
      }
    },
    selectUser({rowIndex, row}) {
      this.newItem = false
      this.$store.commit('sidebars/changeRightSidebar', true)

      const {id} = this.$route.params
      if (row !== undefined && id !== row.id) this.$router.push({name: 'users-id', params: {id: row.id}})
    },
    showRightSidebar() {
      this.newItem = true
      this.$store.commit('sidebars/changeRightSidebar', true)
      this.itemInfo = {}
    },
    updatePassword() {
      this.$refs.passwordForm.validate().then(success => {
        if (success) {
          UsersService.updateUser(this.$route.params.id, {password: this.newPassword}, true)
            .then(() => {
              this.$toast.success('Новый пароль изменен')
              this.showModal = false
            })
            .catch(e => {
              errorHandler(e)
            })
        }
      })
    },
    handleRegister() {
      this.$refs.userForm.validate().then(success => {
        if (success) {
          this.loadingCreateUser = true
          const userToSend = {...this.user}

          // Убираем поле amocrm_user_id, если оно пустое
          if (!userToSend.amocrm_user_id) {
            delete userToSend.amocrm_user_id
          }

          AuthService.registerUser(userToSend)
            .then(() => {
              this.loadingCreateUser = false
              this.$store.commit('sidebars/changeRightSidebar', false)
              this.$toast.success('Пользователь создан.')
              UsersService.getAllUsers()
              this.user = {
                name: '',
                email: '',
                password: '',
                amocrm_user_id: '',
                roles: [],
              }
              this.usersRoles = []
              this.selectedUsersRoles = []
              this.selectedUserAmoCRM = {
                title: '',
                value: '',
              }
            })
            .catch(e => {
              errorHandler(e)
              this.loadingCreateUser = false
            })
        } else {
          this.$toast.error('Заполните все поля')
        }
      })
    },
    archiveItems() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.state.users.selectedItemsForDelete.forEach(async (item, index, arr) => {
        if (index + 1 !== arr.length) {
          await UsersService.deleteItems()
        } else {
          await UsersService.deleteItems().then(r => {
            this.$toast.success(r.data['message'])
            this.updateData()
          })
        }
      })
    },
    returnFromArchive() {
      UsersService.archiveItems().then(r => {
        this.$toast.success(r.data['message'])
        this.updateData()
      })
    },
    deleteItem() {
      this.$refs['table-container'].loadingDelete = true
      UsersService.deleteItems(true)
        .then(r => {
          this.$toast.success(r.data.message)
          this.$refs['table-container'].loadingDelete = false
          this.$refs['table-container'].showDeleteModal = false
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('users/itemsForDelete', [])
          eventBus.$emit('new-state', false)
          this.updateData()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
  },
  beforeDestroy() {
    this.$store.commit('users/getAllUsers', {
      data: [],
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__container {
    margin: 22px 22px;
  }
}

@media screen and (max-width: 1024px) {
  .sidebar {
    &__container {
      width: 100%;
      margin: 95px 22px 22px;
    }
  }
}
</style>
